<template>
	<form type="POST" v-on:submit.prevent="updateProductosTipos" ref="productosTiposForm">
		<h3 class="mb-3 text-primary">
			<font-awesome-icon :icon="faEdit" /> {{title}}
		</h3>
		<div class="card">
			<div class="card-body">
				<div class="mb-3">
					<label for="descripcion">Descripción:</label>
					<input type="text" name="descripcion" id="descripcion" class="form-control" placeholder="Ingrese descripción" v-model="form.DESCRIPCION_TIPO" maxlength="150" required :disabled="loading">
					<span v-if="errors.DESCRIPCION_TIPO" class="text-danger">
						{{errors.DESCRIPCION_TIPO[0]}}
                    </span>
                </div>

                <div class="mb-3">
					<UploadFile id="adjuntos" tipo="productos-tipos" :adjuntos="adjuntos" icon="ImageIcon" accept="image/*" class="h-100 w-100" limitFile="1" :codigo="codigo"></UploadFile>
				</div>
			</div>
			<div class="card-footer">
				<div class="d-flex justify-content-end align-items-center">
					<div class="me-2">
						<router-link :to="{name: 'admin.productos.tipos.index'}" class="btn btn-link">
							Cancelar
						</router-link>
					</div>
					<div>
						<button type="submit" class="btn btn-primary" :disabled="loading || !validateForm">
							<BtnLoading v-if="loadingTipos" />
                            <span v-else>
                                <font-awesome-icon :icon="faSave" /> Actualizar tipo
                            </span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>
<script type="text/javascript">
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
	import { faSave, faEdit } from '@fortawesome/free-solid-svg-icons'
	import BtnLoading from '@/components/BtnLoading.vue'
	import UploadFile from '@/components/UploadFile.vue'
	export default {
		components: {
			FontAwesomeIcon,
			BtnLoading,
			UploadFile
		},
		data(){
			return {
				faSave,
				faEdit,
				form: {
					DESCRIPCION_TIPO: '',
				},
				adjuntos: [],
				loadingTipos: false,
				validateForm: false
			}
		},
		created(){
			this.loadData()
		},
		methods: {
			loadData(){
				this.$store.dispatch('showProductosTipos', {
					id: this.codigo
				}).then(response => {
					if (response.data) {
						this.form.DESCRIPCION_TIPO = response.data.DESCRIPCION_TIPO || ''
						this.adjuntos = response.data.adjuntos
					}
					return response
				}).catch(error =>{
					this.$toasted.show('Error al leer registro')
					this.$router.push({name: 'admin.productos.tipos.index'})
					return error
				})
			},
			updateProductosTipos(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				this.loadingTipos = true
				this.form.id = this.codigo
				this.$store.dispatch('updateProductosTipos', this.form).then((res) => {
					this.$toast.info(`El tipo del producto ha sido actualizado`)
					this.$router.push({name: 'admin.productos.tipos.index'})
					return res
				}).catch(err => {
					if(err.response){
						if(typeof err.response.data === 'string'){
							this.$toast.error(err.response.data)
						}else{
							this.$toast.error('Error al crear registro')
						}
					}
				}).then(() => {
					this.loadingTipos = false
				})
			},
			checkValidity(){
				if (!this.$refs.productosTiposForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.productosTiposForm.checkValidity()
				}
			},
		},

		computed:{
			codigo(){
				return this.$route.params.id || -1
			},
			title(){
				return this.$route.meta.title ? this.$route.meta.title : ''
			},

			loading() {
				return this.$store.getters.loading
			},

			errors() {
				return this.$store.getters.errors || {}
			}
		},
		watch: {
			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		}
	}
</script>